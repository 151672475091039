.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: var(--grey-800);
}

.headerLink {
  min-width: 170px;
  max-width: 225px;
}

.logo {
  width: 100%;
}
